<script setup lang="ts">
import { useChip } from '../composables/useChip';
import type { AppChipModelValue } from '../types';
import type { AppChipProps } from './types';

const props = withDefaults(defineProps<AppChipProps>(), {
  label: '',
  name: '',
  type: 'primary',
});

const modelValue = defineModel<AppChipModelValue>({
  default: [],
});

const { defaultClassList, updateModelValue } = useChip({
  modelValue,
  props,
});

const chipClassList = computed(() => ([
  `app-chip-${props.type}`,
  defaultClassList.value,
]));
</script>

<template>
  <button
    class="app-chip"
    :class="chipClassList"
    @click="updateModelValue(name)">
    <slot name="icon-left" />
    {{ label }}
  </button>
</template>

<style lang="scss" src="./style.scss" />
